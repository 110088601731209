import { useEffect, useState, useRef } from "react";
import {
    PageContainer,
    Label,
    PageBox,
    BoxContainer,
    ClickButton,
    InputBox,
    PromptDialog,
    MenuBar
} from "../../components";
import { Box, Button, Stack } from "@mui/material";
import { lookupContent, updateContent } from "../../redux/actions";
import { Link } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

const SiteAdminPage = () => {
    const [status, setStatus] = useState(0);
    const [open, setOpen] = useState(false);
    const [text, setText] = useState("");

    const [body, setBody] = useState("");
    const [body1, setBody1] = useState("");
    const [body2, setBody2] = useState("");
    const [body3, setBody3] = useState("");
    const [body4, setBody4] = useState("");

    const [locked, setLocked] = useState(false);
    const [password, setPassword] = useState("");
    const [wrong, setWrong] = useState("");

    const [category, setCategory] = useState("edit");

    const editorRef = useRef(null);
    const editorRef1 = useRef(null);
    const editorRef2 = useRef(null);
    const editorRef3 = useRef(null);
    const editorRef4 = useRef(null);

    const handleOpen = (text) => {
        setText(text);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEditorChange = (newContent, editor) => {
        setBody(newContent);
    }

    const handleEditorChange1 = (newContent, editor) => {
        setBody1(newContent);
    }

    const handleEditorChange2 = (newContent, editor) => {
        setBody2(newContent);
    }

    const handleEditorChange3 = (newContent, editor) => {
        setBody3(newContent);
    }

    const handleEditorChange4 = (newContent, editor) => {
        setBody4(newContent);
    }

    const onSave = () => {
        updateContent(
            {
                find: { type: "privacy" },
                update: { body },
            },
            (res) => {
                console.log("update privacy body]");
                handleOpen("Changes saved!");
            }
        );
    }

    const onSave1 = () => {
        updateContent(
            {
                find: { type: "terms" },
                update: { body: body1 },
            },
            (res) => {
                console.log("update terms body]");
                handleOpen("Changes saved!");
            }
        );
    }

    const onSave2 = () => {
        updateContent(
            {
                find: { type: "help" },
                update: { body: body2 },
            },
            (res) => {
                console.log("update help body]");
                handleOpen("Changes saved!");
            }
        );
    }

    const onSave3 = () => {
        updateContent(
            {
                find: { type: "about" },
                update: { body: body3 },
            },
            (res) => {
                console.log("update about body]");
                handleOpen("Changes saved!");
            }
        );
    }

    const onSave4 = () => {
        updateContent(
            {
                find: { type: "faq" },
                update: { body: body4 },
            },
            (res) => {
                console.log("update faq body]");
                handleOpen("Changes saved!");
            }
        );
    }

    useEffect(() => {
        lookupContent({ type: "privacy" }, (res) => {
            if (res.data.result) {
                setBody(res.data.data[0].body);
            }
        });
    }, []);

    useEffect(() => {
        if (status === 0) {
            lookupContent({ type: "privacy" }, (res) => {
                if (res.data.result) {
                    setBody(res.data.data[0].body);
                }
            });
        } else if (status === 1) {
            lookupContent({ type: "terms" }, (res) => {
                if (res.data.result) {
                    setBody1(res.data.data[0].body);
                }
            });
        } else if (status === 2) {
            lookupContent({ type: "help" }, (res) => {
                if (res.data.result) {
                    setBody2(res.data.data[0].body);
                }
            });
        } else if (status === 3) {
            lookupContent({ type: "about" }, (res) => {
                if (res.data.result) {
                    setBody3(res.data.data[0].body);
                }
            });
        } else if (status === 4) {
            lookupContent({ type: "faq" }, (res) => {
                if (res.data.result) {
                    setBody4(res.data.data[0].body);
                }
            });
        }
    }, [status]);

    useEffect(() => {
        const localLocked = localStorage.getItem("locked");
        if (localLocked === "Leavefeedback2024$") {
            setLocked(true);
            setWrong(false);
        } else {
            setLocked(false);
        }
        if (category === "logout") {
            handleLogout();
        }
    }, [category])

    const handleUnlock = () => {
        if (password === "Leavefeedback2024$") {
            localStorage.setItem("locked", "Leavefeedback2024$");
            setLocked(true);
            setWrong(false);
        } else {
            setLocked(false);
            setWrong(true);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("locked");
        setLocked(false);
    };

    return (
        <PageContainer>
            <PageBox>
                <BoxContainer sx={{ maxWidth: "none" }}>
                    <Link to="/">
                        <Label
                            text="Leave Feedback"
                            sx={{
                                color: "#7030A0",
                                fontSize: "20px",
                            }}
                        />
                    </Link>
                    {locked ? (
                        <>
                            <MenuBar
                                items={[
                                    { label: "Edit", value: "edit" },
                                    { label: "Logout", value: "logout" },
                                ]}
                                value={category}
                                base="admin"
                                onItemClick={(item) => {
                                    if (item.value === "logout") {
                                        handleLogout();
                                    }
                                }}
                            />
                            {
                                category === "edit" && (
                                    <Box>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{ justifyContent: "center" }}
                                        >
                                            <Link onClick={() => setStatus(0)} to="#privacy" >
                                                <Label
                                                    sx={
                                                        status === 0
                                                            ? { textDecoration: "underline" }
                                                            : { fontWeight: "400px" }
                                                    }
                                                >
                                                    {status === 0 ? <u>{"Privacy"}</u> : <>{"Privacy"}</>}
                                                </Label>
                                            </Link>
                                            <div>
                                                <Label>|</Label>
                                            </div>
                                            <Link onClick={() => setStatus(1)} to="#terms" className="tab">
                                                <Label
                                                    sx={
                                                        status === 1
                                                            ? { textDecoration: "underline" }
                                                            : { fontWeight: "400px" }
                                                    }
                                                >
                                                    {status === 1 ? <u>{"Terms"}</u> : <>{"Terms"}</>}
                                                </Label>
                                            </Link>
                                            <div>
                                                <Label>|</Label>
                                            </div>
                                            <Link onClick={() => setStatus(2)} to="#help" className="tab">
                                                <Label
                                                    sx={
                                                        status === 2
                                                            ? { textDecoration: "underline" }
                                                            : { fontWeight: "400px" }
                                                    }
                                                >
                                                    {status === 2 ? <u>{"Help"}</u> : <>{"Help"}</>}
                                                </Label>
                                            </Link>
                                            <div>
                                                <Label>|</Label>
                                            </div>
                                            <Link onClick={() => setStatus(3)} to="#about" className="tab">
                                                <Label
                                                    sx={
                                                        status === 3
                                                            ? { textDecoration: "underline" }
                                                            : { fontWeight: "400px" }
                                                    }
                                                >
                                                    {status === 3 ? <u>{"About"}</u> : <>{"About"}</>}
                                                </Label>
                                            </Link>
                                            <div>
                                                <Label>|</Label>
                                            </div>
                                            <Link onClick={() => setStatus(4)} to="#faq" className="tab">
                                                <Label
                                                    sx={
                                                        status === 4
                                                            ? { textDecoration: "underline" }
                                                            : { fontWeight: "400px" }
                                                    }
                                                >
                                                    {status === 4 ? <u>{"Faq"}</u> : <>{"Faq"}</>}
                                                </Label>
                                            </Link>
                                        </Stack>
                                        {
                                            status === 0 && (
                                                <Box>
                                                    <Editor
                                                        className="bodyImage"
                                                        apiKey="2f6bq0amq1spsehackaetyssgzqfx3qqqjzwci92qgmcaqd0"
                                                        value={body}
                                                        onEditorChange={handleEditorChange}
                                                        init={{
                                                            height: 600,
                                                            statusbar: false,
                                                            plugins: ["lists"],
                                                            toolbar: `
                                        undo redo | 
                                        blocks | 
                                        bold italic | 
                                        alignleft aligncenter alignright alignjustify | 
                                        outdent indent | 
                                        bullist numlist
                                    `,
                                                            placeholder: "(Type optional body text or share links)",
                                                            content_style: `
                                        body {
                                            font-family: "Roboto", sans-serif !important;
                                        }
                                        img {
                                            width: 100% !important; 
                                        }
                                        .mce-content-body::before {
                                            color: rgba(0, 0, 0, 0.3) !important; 
                                            pointer-events: none;
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                        }
                                        `,
                                                            setup: (editor) => {
                                                                editorRef.current = editor;
                                                                editor.on("init", () => {
                                                                    const style = document.createElement("style");
                                                                    style.textContent = `
                                            .tox .tox-edit-area::before {
                                                border-radius: 10px !important; 
                                            }
                                            .mce-content-body::before {
                                                color: rgba( 0, 0, 0, 0.3) !important;
                                            }
                                            `;
                                                                    document.head.appendChild(style);
                                                                });
                                                                editor.on("OpenWindow", (e) => {
                                                                    const dialogHeader = document.querySelector(
                                                                        ".tox-dialog__header .tox-dialog__title"
                                                                    );
                                                                    dialogHeader.style.fontWeight = "bold";
                                                                    dialogHeader.style.color = "black";
                                                                    if (
                                                                        dialogHeader &&
                                                                        dialogHeader.textContent === "Insert/Edit Link"
                                                                    ) {
                                                                        dialogHeader.textContent = "Insert / Edit Link";
                                                                    }
                                                                    const labels = document.querySelectorAll(".tox-label");
                                                                    const textfields =
                                                                        document.querySelectorAll(".tox-textfield");
                                                                    if (labels.length > 0) {
                                                                        labels[0].textContent = "Web address (URL)";
                                                                        labels.forEach((label) => {
                                                                            label.style.fontWeight = "bold";
                                                                            label.style.color = "black";
                                                                            label.style.fontSize = "16px";
                                                                        });
                                                                    }
                                                                    if (textfields.length > 0) {
                                                                        textfields[0].placeholder = "https://example.com";
                                                                        textfields[1].placeholder = "Type label";
                                                                        textfields[0].style.marginBottom = "10px";
                                                                        textfields.forEach((textfield) => {
                                                                            textfield.style.fontSize = "16px";
                                                                        });
                                                                    }
                                                                    const style = document.createElement("style");
                                                                    style.textContent = `
                                                .tox-textfield::placeholder {
                                                    color: rgba(0, 0, 0, 0.3); 
                                                }
                                                `;

                                                                    document.head.appendChild(style);
                                                                });
                                                            },
                                                        }}
                                                    />
                                                    <Box sx={{ marginTop: "10px", display: "flex", justifyContent: "flex-end" }}>
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                color: "black",
                                                                bgcolor: "#5ed286",
                                                                textTransform: "none",
                                                                fontWeight: 600,
                                                                ":hover": { bgcolor: "#4ec276" },
                                                                minWidth: "80px",
                                                            }}
                                                            onClick={onSave}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                        {
                                            status === 1 && (
                                                <Box>
                                                    <Editor
                                                        className="bodyImage"
                                                        apiKey="2f6bq0amq1spsehackaetyssgzqfx3qqqjzwci92qgmcaqd0"
                                                        value={body1}
                                                        onEditorChange={handleEditorChange1}
                                                        init={{
                                                            height: 600,
                                                            statusbar: false,
                                                            plugins: ["lists"],
                                                            toolbar: `
                                        undo redo | 
                                        blocks | 
                                        bold italic | 
                                        alignleft aligncenter alignright alignjustify | 
                                        outdent indent | 
                                        bullist numlist
                                    `,
                                                            placeholder: "(Type optional body text or share links)",
                                                            content_style: `
                                        body {
                                            font-family: "Roboto", sans-serif !important;
                                        }
                                        img {
                                            width: 100% !important; 
                                        }
                                        .mce-content-body::before {
                                            color: rgba(0, 0, 0, 0.3) !important; 
                                            pointer-events: none;
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                        }
                                        `,
                                                            setup: (editor) => {
                                                                editorRef1.current = editor;
                                                                editor.on("init", () => {
                                                                    const style = document.createElement("style");
                                                                    style.textContent = `
                                            .tox .tox-edit-area::before {
                                                border-radius: 10px !important; 
                                            }
                                            .mce-content-body::before {
                                                color: rgba( 0, 0, 0, 0.3) !important;
                                            }
                                            `;
                                                                    document.head.appendChild(style);
                                                                });
                                                                editor.on("OpenWindow", (e) => {
                                                                    const dialogHeader = document.querySelector(
                                                                        ".tox-dialog__header .tox-dialog__title"
                                                                    );
                                                                    dialogHeader.style.fontWeight = "bold";
                                                                    dialogHeader.style.color = "black";
                                                                    if (
                                                                        dialogHeader &&
                                                                        dialogHeader.textContent === "Insert/Edit Link"
                                                                    ) {
                                                                        dialogHeader.textContent = "Insert / Edit Link";
                                                                    }
                                                                    const labels = document.querySelectorAll(".tox-label");
                                                                    const textfields =
                                                                        document.querySelectorAll(".tox-textfield");
                                                                    if (labels.length > 0) {
                                                                        labels[0].textContent = "Web address (URL)";
                                                                        labels.forEach((label) => {
                                                                            label.style.fontWeight = "bold";
                                                                            label.style.color = "black";
                                                                            label.style.fontSize = "16px";
                                                                        });
                                                                    }
                                                                    if (textfields.length > 0) {
                                                                        textfields[0].placeholder = "https://example.com";
                                                                        textfields[1].placeholder = "Type label";
                                                                        textfields[0].style.marginBottom = "10px";
                                                                        textfields.forEach((textfield) => {
                                                                            textfield.style.fontSize = "16px";
                                                                        });
                                                                    }
                                                                    const style = document.createElement("style");
                                                                    style.textContent = `
                                                .tox-textfield::placeholder {
                                                    color: rgba(0, 0, 0, 0.3); 
                                                }
                                                `;

                                                                    document.head.appendChild(style);
                                                                });
                                                            },
                                                        }}
                                                    />
                                                    <Box sx={{ marginTop: "10px", display: "flex", justifyContent: "flex-end" }}>
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                color: "black",
                                                                bgcolor: "#5ed286",
                                                                textTransform: "none",
                                                                fontWeight: 600,
                                                                ":hover": { bgcolor: "#4ec276" },
                                                                minWidth: "80px",
                                                            }}
                                                            onClick={onSave1}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                        {
                                            status === 2 && (
                                                <Box>
                                                    <Editor
                                                        className="bodyImage"
                                                        apiKey="2f6bq0amq1spsehackaetyssgzqfx3qqqjzwci92qgmcaqd0"
                                                        value={body2}
                                                        onEditorChange={handleEditorChange2}
                                                        init={{
                                                            height: 600,
                                                            statusbar: false,
                                                            plugins: ["lists"],
                                                            toolbar: `
                                        undo redo | 
                                        blocks | 
                                        bold italic | 
                                        alignleft aligncenter alignright alignjustify | 
                                        outdent indent | 
                                        bullist numlist
                                    `,
                                                            placeholder: "(Type optional body text or share links)",
                                                            content_style: `
                                        body {
                                            font-family: "Roboto", sans-serif !important;
                                        }
                                        img {
                                            width: 100% !important; 
                                        }
                                        .mce-content-body::before {
                                            color: rgba(0, 0, 0, 0.3) !important; 
                                            pointer-events: none;
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                        }
                                        `,
                                                            setup: (editor) => {
                                                                editorRef2.current = editor;
                                                                editor.on("init", () => {
                                                                    const style = document.createElement("style");
                                                                    style.textContent = `
                                            .tox .tox-edit-area::before {
                                                border-radius: 10px !important; 
                                            }
                                            .mce-content-body::before {
                                                color: rgba( 0, 0, 0, 0.3) !important;
                                            }
                                            `;
                                                                    document.head.appendChild(style);
                                                                });
                                                                editor.on("OpenWindow", (e) => {
                                                                    const dialogHeader = document.querySelector(
                                                                        ".tox-dialog__header .tox-dialog__title"
                                                                    );
                                                                    dialogHeader.style.fontWeight = "bold";
                                                                    dialogHeader.style.color = "black";
                                                                    if (
                                                                        dialogHeader &&
                                                                        dialogHeader.textContent === "Insert/Edit Link"
                                                                    ) {
                                                                        dialogHeader.textContent = "Insert / Edit Link";
                                                                    }
                                                                    const labels = document.querySelectorAll(".tox-label");
                                                                    const textfields =
                                                                        document.querySelectorAll(".tox-textfield");
                                                                    if (labels.length > 0) {
                                                                        labels[0].textContent = "Web address (URL)";
                                                                        labels.forEach((label) => {
                                                                            label.style.fontWeight = "bold";
                                                                            label.style.color = "black";
                                                                            label.style.fontSize = "16px";
                                                                        });
                                                                    }
                                                                    if (textfields.length > 0) {
                                                                        textfields[0].placeholder = "https://example.com";
                                                                        textfields[1].placeholder = "Type label";
                                                                        textfields[0].style.marginBottom = "10px";
                                                                        textfields.forEach((textfield) => {
                                                                            textfield.style.fontSize = "16px";
                                                                        });
                                                                    }
                                                                    const style = document.createElement("style");
                                                                    style.textContent = `
                                                .tox-textfield::placeholder {
                                                    color: rgba(0, 0, 0, 0.3); 
                                                }
                                                `;

                                                                    document.head.appendChild(style);
                                                                });
                                                            },
                                                        }}
                                                    />
                                                    <Box sx={{ marginTop: "10px", display: "flex", justifyContent: "flex-end" }}>
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                color: "black",
                                                                bgcolor: "#5ed286",
                                                                textTransform: "none",
                                                                fontWeight: 600,
                                                                ":hover": { bgcolor: "#4ec276" },
                                                                minWidth: "80px",
                                                            }}
                                                            onClick={onSave2}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                        {
                                            status === 3 && (
                                                <Box>
                                                    <Editor
                                                        className="bodyImage"
                                                        apiKey="2f6bq0amq1spsehackaetyssgzqfx3qqqjzwci92qgmcaqd0"
                                                        value={body3}
                                                        onEditorChange={handleEditorChange3}
                                                        init={{
                                                            height: 600,
                                                            statusbar: false,
                                                            plugins: ["lists"],
                                                            toolbar: `
                                        undo redo | 
                                        blocks | 
                                        bold italic | 
                                        alignleft aligncenter alignright alignjustify | 
                                        outdent indent | 
                                        bullist numlist
                                    `,
                                                            placeholder: "(Type optional body text or share links)",
                                                            content_style: `
                                        body {
                                            font-family: "Roboto", sans-serif !important;
                                        }
                                        img {
                                            width: 100% !important; 
                                        }
                                        .mce-content-body::before {
                                            color: rgba(0, 0, 0, 0.3) !important; 
                                            pointer-events: none;
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                        }
                                        `,
                                                            setup: (editor) => {
                                                                editorRef3.current = editor;
                                                                editor.on("init", () => {
                                                                    const style = document.createElement("style");
                                                                    style.textContent = `
                                            .tox .tox-edit-area::before {
                                                border-radius: 10px !important; 
                                            }
                                            .mce-content-body::before {
                                                color: rgba( 0, 0, 0, 0.3) !important;
                                            }
                                            `;
                                                                    document.head.appendChild(style);
                                                                });
                                                                editor.on("OpenWindow", (e) => {
                                                                    const dialogHeader = document.querySelector(
                                                                        ".tox-dialog__header .tox-dialog__title"
                                                                    );
                                                                    dialogHeader.style.fontWeight = "bold";
                                                                    dialogHeader.style.color = "black";
                                                                    if (
                                                                        dialogHeader &&
                                                                        dialogHeader.textContent === "Insert/Edit Link"
                                                                    ) {
                                                                        dialogHeader.textContent = "Insert / Edit Link";
                                                                    }
                                                                    const labels = document.querySelectorAll(".tox-label");
                                                                    const textfields =
                                                                        document.querySelectorAll(".tox-textfield");
                                                                    if (labels.length > 0) {
                                                                        labels[0].textContent = "Web address (URL)";
                                                                        labels.forEach((label) => {
                                                                            label.style.fontWeight = "bold";
                                                                            label.style.color = "black";
                                                                            label.style.fontSize = "16px";
                                                                        });
                                                                    }
                                                                    if (textfields.length > 0) {
                                                                        textfields[0].placeholder = "https://example.com";
                                                                        textfields[1].placeholder = "Type label";
                                                                        textfields[0].style.marginBottom = "10px";
                                                                        textfields.forEach((textfield) => {
                                                                            textfield.style.fontSize = "16px";
                                                                        });
                                                                    }
                                                                    const style = document.createElement("style");
                                                                    style.textContent = `
                                                .tox-textfield::placeholder {
                                                    color: rgba(0, 0, 0, 0.3); 
                                                }
                                                `;

                                                                    document.head.appendChild(style);
                                                                });
                                                            },
                                                        }}
                                                    />
                                                    <Box sx={{ marginTop: "10px", display: "flex", justifyContent: "flex-end" }}>
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                color: "black",
                                                                bgcolor: "#5ed286",
                                                                textTransform: "none",
                                                                fontWeight: 600,
                                                                ":hover": { bgcolor: "#4ec276" },
                                                                minWidth: "80px",
                                                            }}
                                                            onClick={onSave3}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                        {
                                            status === 4 && (
                                                <Box>
                                                    <Editor
                                                        className="bodyImage"
                                                        apiKey="2f6bq0amq1spsehackaetyssgzqfx3qqqjzwci92qgmcaqd0"
                                                        value={body4}
                                                        onEditorChange={handleEditorChange4}
                                                        init={{
                                                            height: 600,
                                                            statusbar: false,
                                                            plugins: ["lists"],
                                                            toolbar: `
                                        undo redo | 
                                        blocks | 
                                        bold italic | 
                                        alignleft aligncenter alignright alignjustify | 
                                        outdent indent | 
                                        bullist numlist
                                    `,
                                                            placeholder: "(Type optional body text or share links)",
                                                            content_style: `
                                        body {
                                            font-family: "Roboto", sans-serif !important;
                                        }
                                        img {
                                            width: 100% !important; 
                                        }
                                        .mce-content-body::before {
                                            color: rgba(0, 0, 0, 0.3) !important; 
                                            pointer-events: none;
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                        }
                                        `,
                                                            setup: (editor) => {
                                                                editorRef4.current = editor;
                                                                editor.on("init", () => {
                                                                    const style = document.createElement("style");
                                                                    style.textContent = `
                                            .tox .tox-edit-area::before {
                                                border-radius: 10px !important; 
                                            }
                                            .mce-content-body::before {
                                                color: rgba( 0, 0, 0, 0.3) !important;
                                            }
                                            `;
                                                                    document.head.appendChild(style);
                                                                });
                                                                editor.on("OpenWindow", (e) => {
                                                                    const dialogHeader = document.querySelector(
                                                                        ".tox-dialog__header .tox-dialog__title"
                                                                    );
                                                                    dialogHeader.style.fontWeight = "bold";
                                                                    dialogHeader.style.color = "black";
                                                                    if (
                                                                        dialogHeader &&
                                                                        dialogHeader.textContent === "Insert/Edit Link"
                                                                    ) {
                                                                        dialogHeader.textContent = "Insert / Edit Link";
                                                                    }
                                                                    const labels = document.querySelectorAll(".tox-label");
                                                                    const textfields =
                                                                        document.querySelectorAll(".tox-textfield");
                                                                    if (labels.length > 0) {
                                                                        labels[0].textContent = "Web address (URL)";
                                                                        labels.forEach((label) => {
                                                                            label.style.fontWeight = "bold";
                                                                            label.style.color = "black";
                                                                            label.style.fontSize = "16px";
                                                                        });
                                                                    }
                                                                    if (textfields.length > 0) {
                                                                        textfields[0].placeholder = "https://example.com";
                                                                        textfields[1].placeholder = "Type label";
                                                                        textfields[0].style.marginBottom = "10px";
                                                                        textfields.forEach((textfield) => {
                                                                            textfield.style.fontSize = "16px";
                                                                        });
                                                                    }
                                                                    const style = document.createElement("style");
                                                                    style.textContent = `
                                                .tox-textfield::placeholder {
                                                    color: rgba(0, 0, 0, 0.3); 
                                                }
                                                `;

                                                                    document.head.appendChild(style);
                                                                });
                                                            },
                                                        }}
                                                    />
                                                    <Box sx={{ marginTop: "10px", display: "flex", justifyContent: "flex-end" }}>
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                color: "black",
                                                                bgcolor: "#5ed286",
                                                                textTransform: "none",
                                                                fontWeight: 600,
                                                                ":hover": { bgcolor: "#4ec276" },
                                                                minWidth: "80px",
                                                            }}
                                                            onClick={onSave4}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                    </Box>
                                )
                            }

                        </>
                    ) : (
                        <>
                            <Label text={"Please enter password."} sx={{ mb: 2 }} />
                            <InputBox
                                type="password"
                                value={password}
                                func={setPassword}
                                onConfirm={handleUnlock}
                            />
                            {wrong && <p style={{ color: "red" }}>wrong password!</p>}

                            <ClickButton onClick={handleUnlock} sx={{ mt: 2 }}>
                                Confirm
                            </ClickButton>
                        </>
                    )}
                </BoxContainer>
                <PromptDialog open={open} onClose={handleClose}>
                    <Label sx={{ fontWeight: 400 }} text={text} />
                </PromptDialog>
            </PageBox>
        </PageContainer>
    );
};

export default SiteAdminPage;