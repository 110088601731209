import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { lookupResume } from "../redux/actions";
import { BoxContainer, PageBox, PageContainer } from "../components";

export const ResumePage = () => {
    const params = useParams();
    const [resume, setResume] = useState(null);
    const [load, setLoad] = useState(false);

    useEffect(() => {
        if (params.id && params.resumeId) {
            lookupResume({ resumeId: params?.resumeId }, (res) => {
                if (res.result) {
                    setResume(res.data);
                    setLoad(true);
                }
            });
        }
    }, [params.id, params.resumeId]);

    return (
        <PageContainer>
            <PageBox>
                {load && (
                    <>
                        {resume.resume && (
                            <>
                                {resume.resume.endsWith(".pdf") ? (
                                    <iframe
                                        src={resume.resume}
                                        width="100%"
                                        height="600px"
                                        style={{ border: "none", marginTop: "10px" }}
                                    ></iframe>
                                ) : resume.resume.match(/\.(jpg|jpeg|png|gif)$/) ? (
                                    <img
                                        src={resume.resume}
                                        alt="Preview"
                                        style={{ width: "100%", marginTop: "10px" }}
                                    />
                                ) : resume.resume.endsWith(".doc") || resume.resume.endsWith(".docx") ? (
                                    <iframe
                                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                                            resume.resume
                                        )}`}
                                        width="100%"
                                        height="600px"
                                        style={{ border: "none", marginTop: "10px" }}
                                    ></iframe>
                                ) : (
                                    <p>File format not supported for preview.</p>
                                )}
                            </>
                        )}
                    </>
                )}
            </PageBox>
        </PageContainer>
    );
};
