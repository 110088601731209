import axios from "../axiosConfig";

export const uploadResume = (params, callback) => {
  axios
    .post('/resume/upload', params, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then( callback )
    .catch( err => {
      callback({data: {
        result: false,
        message: "Error :" + err
      }})
    });
};

export const lookupResume = (param, callback) => {
  axios
    .post("/resume/lookup", param)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => callback({data: {result: false, message: "Error : " + error}}));
};
