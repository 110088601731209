import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { exists } from "../redux/actions/user";
import { SearchBar } from "../components/Search/SearchBar";
import {
  Label,
  BoxContainer,
  SubmitButton,
  InputBox,
  PageContainer,
  RedirectSelector,
} from "../components";
import ColorPicker from "mui-color-picker";
import { companyDetail } from "../redux/actions";
import { ManagerInfo } from "../components/ManagerInfo";
import { sampleManagerInfo } from "../constants";
import {
  Alert,
  Box,
  Checkbox,
  Grid,
  Snackbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Typography from "@mui/material/Typography";

export const UploadPage = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const locked = useSelector((state) => state.login);
  const [loaded, setLoaded] = useState(false);
  const [logo, setLogo] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [display, setDisplay] = useState("");
  const [googlePlaceReviews, setGooglePlaceReviews] = useState({
    rating: 0,
    ratingCount: 0,
  });
  const [star, setStar] = useState("#1677ff");
  const [button, setButton] = useState("#1677ff");
  const [managers, setManagers] = useState(sampleManagerInfo);
  const [googleId, setGoogleId] = useState("");
  const [address, setAddress] = useState("");
  const [emailAlert, setEmailAlert] = useState(true);
  const [smsAlert, setSmsAlert] = useState(true);
  const [uploadStatus, setUploadStatus] = useState(0);
  const [four, setFour] = useState(0);
  const [five, setFive] = useState(0);
  const [message, setMessage] = useState(
    "Anyway, the management is hooking me and all my friends up with a 10% discount on in-store purchases for the next week"
  );
  const [expire, setExpire] = useState(7);
  const [redeem, setRedeem] = useState(
    "Just show them this offer message, and enjoy the deal."
  );
  const [email, setEmail] = useState("");
  const fourTitle = "Send users here if 4 stars:";
  const fiveTitle = "Send users here if 5 stars:";
  const values = [[2], [1], [5], [2, 1], [2, 5]];
  const labels = [
    "1) Internal survey page only",
    "2) Google review page directly",
    "3) Customer referral message directly",
    "4) Internal survey, then to Google review",
    "5) Internal survey, then referral message",
  ];
  const [expandedPanels, setExpandedPanels] = useState({
    panel1: false,
    panel2: false,
    panel3: false,
    panel4: false,
  });
  const [fileSizeError, setFileSizeError] = useState("");

  const handlePanelChange = (panel) => (event, isExpanded) => {
    setExpandedPanels((prev) => ({
      ...prev,
      [panel]: isExpanded,
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUnlock = () => {
    document.cookie = `lfologin=${password};path=/`;
    if (!unlockWithPassword(password, managers)) {
      alert("Wrong password !");
    }
  };

  const unlockWithPassword = (password, managers) => {
    if (password === "") {
      return false;
    } else if (password === "Leavefeedback2024$") {
      dispatch({ type: "Login", payload: true });
      return true;
    } else {
      const res = managers.some(
        (manager) => manager.email.length !== 0 && manager.email === password
      );
      if (res) {
        dispatch({ type: "Login", payload: true });
        return true;
      } else return false;
    }
  };

  const handleSave = () => {
    companyDetail(
      {
        company: params.id,
        display,
        star,
        button,
        managers: managers,
        google:
          "http://search.google.com/local/writereview?placeid=" + googleId,
        logo: logo,
        alertSMS: smsAlert,
        alertEmail: emailAlert,
        four,
        five,
        address,
        rating: googlePlaceReviews.rating,
        ratingCount: googlePlaceReviews.ratingCount,
        message,
        expire,
        redeem,
      },
      (newState) => setUploadStatus(newState + 1)
    );
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file.size > 10 * 1024 * 1024) {
      setFileSizeError(
        "File size exceeds the limit of 10 MB. Please choose a smaller file."
      );
      return;
    }
    setFileSizeError("");
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setLogo(reader.result);
    };
  };

  const handleSetEmailAlert = (event) => {
    setEmailAlert(event.target.checked);
  };

  const handleSetSmsAlert = (event) => {
    setSmsAlert(event.target.checked);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(email);
  };

  useEffect(() => {
    const weekday = new Date();
    weekday.setTime(weekday.getTime() + expire * 24 * 60 * 60 * 1000);
    const formattedDate = `${(weekday.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${weekday
      .getDate()
      .toString()
      .padStart(2, "0")}/${weekday.getFullYear()}`;

    setEmail(
      `Hi! I really enjoyed my experience at ${display}. I filled out a survey, giving the team 5 stars for their great service. ${message} (offer ends ${formattedDate}). ${redeem}\n\nHere is the address for ${display}:\n${address}`
    );
  }, [message, expire, redeem, display, address]);

  useEffect(() => {
    if (uploadStatus) {
      setOpen(true);
      setTimeout(setUploadStatus, 3500, 0);
    }
  }, [uploadStatus]);

  useEffect(() => {
    exists(params.id, (response) => {
      const { result, data } = response;
      console.log(response, "uploadpage.jsx");
      if (result) {
        setLogo(data.logo);
        setStar(data.star);
        setButton(data.button);
        setGoogleId(data.google.split("=")[1]);
        setManagers(data.managers);
        setEmailAlert(data.alertEmail);
        setSmsAlert(data.alertSMS);
        setDisplay(data.display);
        setFour(data.four ?? 0);
        setFive(data.five ?? 0);
        setAddress(data.address);
        setMessage(
          data.message ??
            "Anyway, the management is hooking me and all my friends up with a 10% discount on in-store purchases for the next week"
        );
        setExpire(data.expire ?? 7);
        setRedeem(
          data.redeem ??
            "Just show them this offer message, and enjoy the deal."
        );
        setGooglePlaceReviews({
          rating: data.rating,
          ratingCount: data.userRatingCount,
        });
      }

      let cookie = decodeURIComponent(document.cookie)
        .split(";")
        .find((c) => c.trim().startsWith("lfologin="));
      if (cookie !== null && cookie !== undefined && cookie !== "")
        unlockWithPassword(cookie.trim().slice(9), data.managers);
      setLoaded(true);
    });
  }, [params.id]);

  return (
    <PageContainer>
      {loaded && (
        <BoxContainer>
          {locked ? (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  fontSize: "18px",
                  justifyContent: "end",
                  padding: "20px",
                }}
              >
                <a
                  style={{ textDecoration: "none" }}
                  href={`/${params.id}/dashboard`}
                >
                  Dashboard
                </a>
                <span> / </span>
                <a
                  style={{ textDecoration: "none" }}
                  href={`/${params.id}/admin`}
                >
                  <b>
                    <u>Admin</u>
                  </b>
                </a>
                <span> / </span>
                <a
                  style={{ textDecoration: "none" }}
                  href={`/${params.id}/payments`}
                >
                  Payment
                </a>
              </div>
              {logo !== undefined && logo !== null && logo !== "" ? (
                <img
                  src={logo}
                  style={{ width: "350px" }}
                  alt="Please upload company logo."
                />
              ) : (
                <Label text="Please upload company logo." />
              )}
              <br />
              <br />
              <Divider
                sx={{ borderColor: star, borderBottomWidth: "medium" }}
              />
              <Accordion
                expanded={expandedPanels.panel1}
                onChange={handlePanelChange("panel1")}
                sx={{
                  boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    expandedPanels.panel1 ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{ padding: 0 }}
                >
                  <Typography color={button} fontSize={"18px"} gutterBottom>
                    <strong>Location Profile</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <div>
                    <button
                      onClick={() => document.getElementById("getFile").click()}
                    >
                      Choose Logo Picture
                    </button>
                    <input
                      type="file"
                      id="getFile"
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={handleImageUpload}
                    />
                  </div>
                  {fileSizeError && (
                    <p style={{ color: "red", fontSize: "13px" }}>
                      {fileSizeError}
                    </p>
                  )}
                  {(logo === undefined || logo === null || logo === "") &&
                    " No image has been selected"}
                  <br></br>
                  <Grid container spacing={2} paddingBottom={2}>
                    <Grid item xs={6}>
                      <Label
                        text="Star Color"
                        lineHeight="0"
                        fontWeight="700"
                        letterSpacing="0"
                      />
                      <ColorPicker
                        id="starColorPicker"
                        value={star}
                        onChange={setStar}
                        label={star}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Label
                        text="Button Color"
                        lineHeight="0"
                        fontWeight="700"
                        letterSpacing="0"
                      />
                      <ColorPicker
                        id="buttonColorPicker"
                        value={button}
                        onChange={(color) => setButton(color)}
                        label={button}
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  <SearchBar
                    setAddress={setAddress}
                    setGoogleId={setGoogleId}
                    setGooglePlaceReviews={setGooglePlaceReviews}
                    setDisplay={setDisplay}
                  ></SearchBar>

                  <Label
                    text="Location Name"
                    lineHeight="0"
                    fontWeight="700"
                    letterSpacing="0"
                  />
                  <InputBox value={display} func={setDisplay} />

                  <Label
                    text="Address"
                    lineHeight="0"
                    fontWeight="700"
                    letterSpacing="0"
                  />
                  <InputBox value={address} func={setAddress} />

                  <Label
                    text="Google review placeID"
                    lineHeight="0"
                    fontWeight="700"
                    letterSpacing="0"
                  />
                  <InputBox value={googleId} func={setGoogleId} />
                </AccordionDetails>
              </Accordion>

              <Divider
                sx={{ borderColor: star, borderBottomWidth: "medium" }}
              />

              <Accordion
                expanded={expandedPanels.panel2}
                onChange={handlePanelChange("panel2")}
                sx={{
                  boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    expandedPanels.panel2 ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{ padding: 0 }}
                >
                  <Typography color={button} fontSize={"18px"} gutterBottom>
                    <strong>Manager Contacts</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={4}>
                      <Label lineHeight="0" fontWeight="700" letterSpacing="0">
                        Names
                      </Label>
                    </Grid>
                    <Grid item xs={4}>
                      <Label lineHeight="0" fontWeight="700" letterSpacing="0">
                        Emails
                      </Label>
                    </Grid>
                    <Grid item xs={4}>
                      <Label lineHeight="0" fontWeight="700" letterSpacing="0">
                        Mobiles
                      </Label>
                    </Grid>
                  </Grid>
                  {managers.map((manager, index) => (
                    <ManagerInfo
                      key={index}
                      rkey={index}
                      array={managers}
                      func={setManagers}
                    />
                  ))}
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Checkbox
                        checked={emailAlert}
                        onChange={handleSetEmailAlert}
                      />
                      Email Alerts
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        checked={smsAlert}
                        onChange={handleSetSmsAlert}
                      />
                      SMS Alerts
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Divider
                sx={{ borderColor: star, borderBottomWidth: "medium" }}
              />
              <Accordion
                expanded={expandedPanels.panel3}
                onChange={handlePanelChange("panel3")}
                sx={{
                  boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    expandedPanels.panel3 ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{ padding: 0 }}
                >
                  <Typography color={button} fontSize={"18px"} gutterBottom>
                    <strong>Customer Forward Options</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <Grid container spacing={2} padding={2}>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}></Grid>
                  </Grid>

                  <RedirectSelector
                    value={four}
                    setValue={setFour}
                    title={fourTitle}
                    values={values}
                    labels={labels}
                  ></RedirectSelector>
                  <RedirectSelector
                    value={five}
                    setValue={setFive}
                    title={fiveTitle}
                    values={values}
                    labels={labels}
                  ></RedirectSelector>
                </AccordionDetails>
              </Accordion>

              <Divider
                sx={{ borderColor: star, borderBottomWidth: "medium" }}
              />
              <Accordion
                expanded={expandedPanels.panel4}
                onChange={handlePanelChange("panel4")}
                sx={{
                  boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    expandedPanels.panel4 ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{ padding: 0 }}
                >
                  <Typography color={button} fontSize={"18px"} gutterBottom>
                    <strong>Customer Referral Message</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <Label lineHeight="0" fontWeight="700" letterSpacing="0">
                    Special Email Message
                  </Label>
                  <Box
                    component="section"
                    sx={{
                      p: 2,
                      mt: 0,
                      border: "1px dashed grey",
                      whiteSpace: "pre-line",
                    }}
                    onClick={handleCopy}
                  >
                    <span>{email}</span>
                  </Box>

                  <Label
                    text="Deal Message (Editable)"
                    lineHeight="0"
                    fontWeight="700"
                    letterSpacing="0"
                  />
                  <InputBox value={message} func={setMessage} />

                  <Label
                    text="Days to Deal Expiration (Editable)"
                    lineHeight="0"
                    fontWeight="700"
                    letterSpacing="0"
                  />
                  <InputBox value={expire} func={setExpire} type="number" />

                  <Label
                    text="Redeeming Deal Steps (Editable)"
                    lineHeight="0"
                    fontWeight="700"
                    letterSpacing="0"
                  />
                  <InputBox value={redeem} func={setRedeem} />
                </AccordionDetails>
              </Accordion>

              <SubmitButton color={button} onClick={handleSave}>
                Save Changes
              </SubmitButton>
              <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity={uploadStatus === 2 ? "success" : "error"}
                  sx={{ width: "100%" }}
                >
                  {uploadStatus === 1
                    ? "Failed to save."
                    : "Successfully saved!"}
                </Alert>
              </Snackbar>
            </>
          ) : (
            <>
              <InputBox 
                type="password" 
                value={password} 
                func={setPassword}
                onConfirm={handleUnlock}
              />
              <SubmitButton color={button} onClick={handleUnlock}>
                Unlock
              </SubmitButton>
            </>
          )}
        </BoxContainer>
      )}
    </PageContainer>
  );
};
