import { Stack } from "@mui/material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { Label } from "./Label";

export const MenuBar = (props) => {
  const { items, value, base = "feed", onChange } = props;

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ overflowX: "auto", scrollbarWidth: "none" }}
    >
      {items.map((menu, index) => (
        <Stack direction="row" spacing={1} key={index}>
          {index !== 0 && (
            <div style={{ color: "black" }}>
              <Label sx={{ color: "black" }}>|</Label>
            </div>
          )}
          <Link to={`/${base}/${menu.value}`} className="tab">
            <Label
              sx={{
                fontWeight: value === menu.value ? 800 : 400,
                overflow: "none",
              }}
            >
              {value === menu.value ? <u>{menu.label}</u> : <>{menu.label}</>}
            </Label>
          </Link>
        </Stack>
      ))}
    </Stack>
  );
};