import { Container, Pagination } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { receive, receiveDetailReview, receiveRecent } from "../redux/actions";
import "./../App.css";
import {
  detailReviewKey,
  sampleManagerInfo,
  series,
  title,
} from "../constants";
import { useNavigate, useParams } from "react-router-dom";
import { exists } from "../redux/actions/user";
import {
  BoxContainer,
  InputBox,
  PageContainer,
  SubmitButton,
} from "../components";
import { Select, MenuItem } from "@mui/material";

const valueFormatter = (value) => `${value === null ? "0" : value.toFixed(1)}%`;

export const AdminPage = () => {
  const chartSetting = {
    width: 350,
    height: 270,
  };

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isPaid = true; 
  const review_normal = useSelector((state) => state.review);
  const review_detail = useSelector((state) => state.reviewdetail);
  const recent = useSelector((state) => state.reviewrecent);
  const locked = useSelector((state) => state.login);
  const [loaded, setLoaded] = useState(false);
  const [detail, setDetail] = useState(series);
  const [average, setAverage] = useState(0);
  const [logo, setLogo] = useState("");
  const [googleCount, setGoogleCount] = useState(0);
  const [button, setButton] = useState("");
  const [password, setPassword] = useState("");
  const [managers, setManagers] = useState(sampleManagerInfo);
  const [total, setTotal] = useState(0);
  const [googleRating, setGoogleRating] = useState({
    rating: 0,
    ratingCount: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredRecent, setFilteredRecent] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const totalPages = Math.ceil(recent.length / itemsPerPage);
  const rowsPerPageOptions = [5, 10, 20, 50, 100];

  const handleRowsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const handleUnlock = () => {
    document.cookie = `lfologin=${password};path=/`;
    if (!unlockWithPassword(password, managers)) {
      alert("Wrong password !");
    }
  };

  const unlockWithPassword = (password, managers) => {
    if (password === "Leavefeedback2024$") {
      dispatch({ type: "Login", payload: true });
      return true;
    } else {
      const res = managers.some(
        (manager) => manager.email.length !== 0 && manager.email === password
      );
      if (res) {
        dispatch({ type: "Login", payload: true });
        return true;
      } else return false;
    }
  };

  useEffect(() => {
    console.log("check this", recent);

  }, [recent])

  useEffect(() => {
    const shape = document.getElementsByTagName("svg")[1];
    shape?.setAttribute("viewBox", "45 0 375 270");
  });

  useEffect(() => {
    dispatch(receive(params.id));
    dispatch(receiveDetailReview(params.id));
    dispatch(receiveRecent(params.id));
    exists(params.id, (response) => {
      const { result, data } = response;
      if (result) {
        setLogo(data.logo);
        setButton(data.button);
        setManagers(data.managers);
        setTotal(data.hyperlink + data.copybutton + data.justclick);
        setGoogleRating({
          rating: data.rating,
          ratingCount: data.ratingCount,
        });

        let cookie = decodeURIComponent(document.cookie)
          .split(";")
          .find((c) => c.trim().startsWith("lfologin="));
        if (cookie !== null && cookie !== undefined && cookie !== "")
          unlockWithPassword(cookie.trim().slice(9), data.managers);
        setLoaded(true);
      } else navigate(`/${params.id}/admin`);
    });
  }, [dispatch, params.id, navigate]);

  useEffect(() => {
    setDetail(
      review_detail.map((val) => ({
        ...val,
        valueFormatter,
      }))
    );
  }, [review_detail]);

  useEffect(() => {
    var sum = 0.0;
    review_normal.forEach((val, index) => {
      const plus = (val.percentage * (5 - index)) / 100;
      if (!index) setGoogleCount(val.percentage);
      sum = sum + plus;
    });
    setAverage(sum.toFixed(1));
  }, [review_normal]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = recent.slice(startIndex, endIndex);
    setFilteredRecent(currentItems);
  }, [currentPage, recent, itemsPerPage]);

  return (
    <>
      {loaded &&
        (locked ? (
          <Container
            maxWidth={false}
            sx={{
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                fontSize: "18px",
                justifyContent: "end",
                padding: "20px",
                color: "black",
                textDecoration: "none",
              }}
            >
              <a
                style={{ textDecoration: "none" }}
                href={`/${params.id}/dashboard`}
              >
                <b>
                  <u>Dashboard</u>
                </b>
              </a>
              <span> / </span>
              <a
                style={{ textDecoration: "none" }}
                href={`/${params.id}/admin`}
              >
                Admin
              </a>
              <span> / </span>
              <a
                style={{ textDecoration: "none" }}
                href={`/${params.id}/payments`}
              >
                Payment
              </a>
            </div>
            {logo !== null && logo !== undefined && logo !== "" && (
              <div style={{ textAlign: "left" }}>
                <img src={logo} style={{ width: "350px" }} alt="logo" />
              </div>
            )}
            <h1>Feedback Dashboard</h1>
            <h2>Results Overview:</h2>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>{Number(average ?? 0).toFixed(1)} ⭐ </td>
                    <td>Leave Feedback: </td>
                    <td>{recent.length.toLocaleString()}</td>
                  </tr>
                </tbody>
              </table>
              <BarChart
                dataset={review_normal}
                yAxis={[
                  {
                    scaleType: "band",
                    dataKey: "level",
                  },
                ]}
                series={[
                  {
                    dataKey: "percentage",
                    color: "#D9D9D9",
                    valueFormatter,
                  },
                ]}
                xAxis={[
                  {
                    label: "Percentage (%)",
                    min: 0,
                    max: 100,
                  },
                ]}
                layout="horizontal"
                {...chartSetting}
              />
              <br />
              <table>
                <tbody>
                  <tr>
                    <td></td>
                    <td>Client Referrals: </td>
                    <td>{total}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Clicks to Google: </td>
                    <td>
                      {((googleCount * recent.length) / 100).toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td>{(googleRating.rating ?? 0).toFixed(1)} ⭐ </td>
                    <td>Google Reviews: </td>
                    <td>{(googleRating.ratingCount ?? 0).toLocaleString()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <h2>Reactions to Services:</h2>
            <div style={{ marginRight: `calc(100vw)`, minWidth: 500 }}>
              <BarChart
                width={350}
                height={270}
                series={detail}
                yAxis={[
                  {
                    id: "yAxis",
                    scaleType: "band",
                    data: title,
                  },
                ]}
                leftAxis={null}
                rightAxis={"yAxis"}
                xAxis={[
                  {
                    label: "Percentage (%)",
                    min: 0,
                    max: 100,
                  },
                ]}
                layout="horizontal"
              ></BarChart>
            </div>

            <br />
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2>Table of Comments:</h2>
              <div style={{ marginLeft: "6rem" }}>
                <Select value={itemsPerPage} onChange={handleRowsPerPageChange}>
                  {rowsPerPageOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div>
              <table className="cfstable">
                <thead className="cfsrow">
                  <tr>
                    <td className="cfscell">Stars</td>
                    <td className="cfscell">Comments & Reactions</td>
                    <td className="cfscell">Date & Time</td>
                    <td className="cfscell">Contact Info</td>
                  </tr>
                </thead>
                <tbody>
                  {filteredRecent.map((val, index) => (
                    <tr key={index} className="cfsrow">
                      <td className="cfscell">{val.rating}</td>
                      <td className="cfscell">
                        {val.review && val.review.length !== 0 && (
                          <p style={{ paddingLeft: "3px" }}>{val.review}</p>
                        )}
                        {val.review_text && val.review_text.length !== 0 && (
                          <p style={{ paddingLeft: "3px" }}>
                            {val.review_text}
                          </p>
                        )}
                        <p>
                          {val.review_score.wait === 0 &&
                          val.review_score.friendliness === 0 &&
                          val.review_score.cleanliness === 0 &&
                          val.review_score.price === 0 &&
                          val.review_score.quality === 0 ? (
                            <></>
                          ) : (
                            detailReviewKey.map((key, ind) => (
                              <span
                                key={ind}
                                style={{
                                  color:
                                    series[val.review_score[key.rkey] - 1]
                                      .color,
                                }}
                              >
                                {series[val.review_score[key.rkey] - 1].label}
                                {title[ind]}{" "}
                              </span>
                            ))
                          )}
                        </p>
                      </td>
                      <td className="cfscell">
                        <p>{val.createdAt.date}</p>
                        <p>{val.createdAt.time}</p>
                      </td>
                      {isPaid ? (
                        <td className="cfscell">
                          <p>{val.name}</p>
                          <p>
                            <a href={`mailto:${val.email}`}>{val.email}</a>
                          </p>
                          <p>
                            <a href={`tel:${val.phone}`}>{val.phone}</a>
                          </p>
                        </td>
                      ) : (
                        <td className="cfscell" style={{ fontWeight: "bold" }}>
                          "Upgrade Plan to Turn On"
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div style={{ paddingLeft: "3rem" }}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  sx={{
                    ".MuiPaginationItem-root": {
                      fontSize: "1rem",
                    },
                  }}
                />
              </div>
            </div>
          </Container>
        ) : (
          <PageContainer>
            <BoxContainer>
              <InputBox type="password" value={password} func={setPassword} />
              <SubmitButton color={button} onClick={handleUnlock}>
                Unlock
              </SubmitButton>
            </BoxContainer>
          </PageContainer>
        ))}
    </>
  );
};
