
import { PageContainer, Label, PageBox, BoxContainer } from "../../components";

import { Box } from "@mui/material";
import { useEffect } from "react";

const LogoutPage = () => {
  useEffect(() => {
    localStorage.removeItem("locked");
  })
  return (
    <PageContainer>
      <PageBox>
        <BoxContainer sx={{ maxWidth: "none" }}>
          <Box sx={{ mt: "10px", width: "300px" }}>
            <Label>You have been logged out.</Label>
          </Box>
        </BoxContainer>
      </PageBox>
    </PageContainer>
  );
};

export default LogoutPage;