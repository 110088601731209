import { BoxContainer, PageBox, PageContainer } from "../../components";
import { useEffect, useState } from "react";
import { lookupContent } from "../../redux/actions";
import { Editor } from "@tinymce/tinymce-react";

const PrivacyPage = () => {
  const [body, setBody] = useState("");

  useEffect(() => {
    lookupContent({ type: "privacy" }, (res) => {
      if (res.data.result) {
        setBody(res.data.data[0].body);
      }
    });
  }, []);

  const handleEditorChange = (newContent, editor) => {
    editor.setContent(body);
  };

  return (
    <PageContainer>
      <PageBox>
        <BoxContainer>
          <div style={{ height: "100vh" }}>
            <Editor
              key={body} 
              className="bodyImage"
              apiKey="2f6bq0amq1spsehackaetyssgzqfx3qqqjzwci92qgmcaqd0"
              value={body}
              onEditorChange={handleEditorChange}
              init={{
                height: "80%",
                menubar: false,
                statusbar: false,
                readonly: true,
                toolbar: false,
                content_style: `
                  body {
                    font-family: "Roboto", sans-serif !important;
                  }
                  img {
                    width: 100% !important; 
                  }
                  a {
                    pointer-events: auto !important;
                    color: blue !important;
                    text-decoration: underline !important;
                    cursor: pointer !important;
                  }
                  
                `,
                setup: (editor) => {
                  editor.on("init", () => {
                    editor.getBody().setAttribute("contenteditable", "false");

                    editor.getBody().addEventListener("click", (event) => {
                      if (event.target.tagName === "A") {
                        event.preventDefault();
                        const url = event.target.href;
                        if (url) {
                          window.open(url, "_blank");
                        }
                      }
                    });
                  });
                },
              }}
            />
          </div>
        </BoxContainer>
      </PageBox>
    </PageContainer>
  );
};

export default PrivacyPage;
