import { BoxContainer, Label } from "../components";
import { Box } from "@mui/material";
import { companyDetail } from "../redux/actions";

export const FriendPage = (props) => {
  const {id, company, star, message, redeem, expire, address} = props;

  const weekday = new Date();
  weekday.setTime(weekday.getTime() + expire * 24 * 60 * 60 * 1000);
  const formattedDate = `${(weekday.getMonth() + 1).toString().padStart(2, '0')}/${weekday.getDate().toString().padStart(2, '0')}/${weekday.getFullYear()}`;

  const handleCopy = (mode) => {
    navigator.clipboard.writeText(email);
    if(mode === 0)
      window.open(`mailto:?cc=support@leavefeedback.org&subject=Check%20this%20Place%20Out&body=${encodeURIComponent(email)}`, "_blank");
    let param;
    if(mode === 0) param = { hyperlink: 1 };
    else if(mode === 1) param = { copybutton: 1 };
    else if(mode === 2) param = { justclick: 1 };
    companyDetail({company: id, $inc: param}, () => {});
  }


  const email = `Hi! I really enjoyed my experience at ${company}. I filled out a survey, giving the team ${star} stars for their great service. ${message} (offer ends ${formattedDate}). ${redeem}\n\nHere is the address for ${company}:\n${address}`

  return(
    <BoxContainer>
      <Label text='Tell your friends about your experience, and you all will get to enjoy a discount from us next time!'/>
      <a onClick={() => handleCopy(0)}  className="tab"  href="#">
        Click to Launch Email with Deal for Friends
      </a>
      <p>OR copy and paste the note from below yourself, and message your friends on any platform.</p>
      <Box component="section" sx={{ p: 2, border: '1px dashed grey', whiteSpace: "pre-line" }} onClick={() => handleCopy(2)}>{email}</Box>
      <button onClick={() => handleCopy(1)}>Copy</button>
    </BoxContainer>
  )
}