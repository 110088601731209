import Rating from '@mui/material/Rating';
import {
  Label,
  SubmitButton,
  BoxContainer,
} from "../components";

export const Homepage = ( props ) => {
  const { rating, setRating, logo, star, button, next } = props;
  const onSubmit = () => {
    if(!rating){
      alert("Please rate the services.");
      return;
    }
    next();
  }

  return (
    <BoxContainer>
      <br />
      <div style={{textAlign: "center"}}>
        {(logo !== null && logo!==undefined && logo!=='') && <img src={logo} style={{width: '350px'}} alt="logo" />}
        <br />
        <br />
        <Label text="How was our service today?" lineHeight={0} />
        <Label text="(choose 1 to 5 stars)" lineHeight={0} fontSize={15} fontWeight={500} />
        <Rating
          value={rating}
          size="large"
          onChange={(event, newValue) => {
            setRating(newValue || 0);
          }}
          style={{ color: star, fontSize: 44 }}
        />
      </div>

      <SubmitButton onClick={onSubmit} color={button}>
        Submit
      </SubmitButton>
    </BoxContainer>
  );
};