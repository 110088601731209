import { TextField } from "@mui/material";
import { useState } from "react";

export const InputBox = (props) => {
  const [error, setError] = useState(false);
  const {
    value,
    func,
    onConfirm,
    regexp,
    ...others
  } = props;

  const filterValue = (str) => {
    if(regexp){
      setError(!regexp.test(str));
    }
  }

  return (
    <>
      <TextField
        sx={{
          "& .MuiInputLabel-root": { color: "black" },
          "& .MuiOutlinedInput-root": {
            "& > fieldset": { borderColor: "black" },
          },
        }}
        label=""
        rows={5}
        required={true}
        error={error}
        fullWidth
        color="primary"
        value={value}
        onChange={(event) => {
          filterValue(event.target.value);
          func(event.target.value);
        }}
        onKeyUp={(event) => {
          if (event.key === "Enter" && onConfirm) onConfirm();
        }}
        inputProps={{
          autoCapitalize: "none",
        }}
        {...others}
      />
    </>
  );
};
