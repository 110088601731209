import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./redux/reducers";
import { AdminPage, UploadPage, ClientPage, RegisterPage, ResumePage, LandingPage, StripePage, SelectPage, MenuPage, JobPage, DealPage, GamePage, EditPage, RedirectPage, SalesPage } from "./pages";
import { Footer } from "./components";
import AboutPage from "./pages/about";
import FaqPage from "./pages/about/faq";
import PrivacyPage from "./pages/about/privacy";
import TermsPage from "./pages/about/terms";
import HelpPage from "./pages/about/help";
import LogoutPage from "./pages/admin/logout";
import SiteAdminPage from "./pages/admin/edit";

const theme = createTheme({

});

function App() {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/about/faq" element={<FaqPage />} />
              <Route path="/about/privacy" element={<PrivacyPage />} />
              <Route path="/about/terms" element={<TermsPage />} />
              <Route path="/about/help" element={<HelpPage />} />
              {/* <Route path="/admin" element={<RegisterPage />} /> */}
              <Route path="/admin" element={<SiteAdminPage />} />
              <Route path="/admin/edit" element={<SiteAdminPage />} />
              <Route path="/admin/logout" element={<LogoutPage />} />
              <Route path="/payments" element={<StripePage />} />
              <Route path="/:id/dashboard/" element={<AdminPage />} />
              <Route path="/:id/admin/" element={<UploadPage />} />
              <Route path="/:id/payments" element={<StripePage />} />
              <Route path="/:id/select" element={<SelectPage />} />
              <Route path="/:id/edit" element={<EditPage />} />
              <Route path="/:id/:page" element={<RedirectPage />} />
              <Route path="/:id/jobs/:resumeId" element={<ResumePage />} />
              <Route path="/:id/" element={<ClientPage />} />
              <Route path="/sales" element={<SalesPage />} />
            </Routes>
          </BrowserRouter>
        </Provider>
      </ThemeProvider>

      <Footer />
    </>
  );
}

export default App;
