import axios from "../axiosConfig";

export const updateContent = (param, callback) => {
  axios
    .post("/content/update", param)
    .then(callback)
    .catch((error) => callback({data: {result: false, message: "Error : " + error}}));
};

export const lookupContent = (param, callback) => {
  axios
    .post("/content/lookup", param)
    .then(callback)
    .catch((error) => callback({data: {result: false, message: "Error : " + error}}));
};