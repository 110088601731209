const initialState = [
];;

const getTimeAndDate = val => {
  const parts = val.split(',');
  const date = parts[0];
  const time = parts[1];
  return { date, time };
}
const convertLocal = val => {
  const localDate = new Date(val).toLocaleString();
  const ind = localDate.lastIndexOf(":");
  return localDate.slice(0, ind) + localDate.slice(ind + 3);
}
const reducer = (state = initialState, action) => {
  if(action.type === "RecentReview"){

    // const sortedPayload = action.payload.sort((a, b) => {
    //   const dateA = new Date(a.createdAt);
    //   const dateB = new Date(b.createdAt);
    //   return dateB - dateA;
    // });
    
    state = action.payload.map(val => ({
      ...val,
      createdAt: getTimeAndDate(convertLocal(val.createdAt))
    }));
  }
  return state;
}

export default reducer;